import ConfiguratorAvailability from "views/ConfiguratorAvailability"
import ConfiguratorLayout from "components/Configurator/Layout"
import { PageProps } from "gatsby"
import React from "react"
import SEO from "components/seo"
import { sessionStore } from "utils/StorageUtils"
import scrollTo from "gatsby-plugin-smoothscroll"
import { trackStep } from "utils/GtmTracking"

type DataProps = {}

const ConfiguratorAvailabilityPage: React.FC<PageProps<DataProps>> = () => {
  React.useEffect(() => {
    const partnerId = sessionStore?.getItem("partnerId")
    if (partnerId) {
      trackStep(partnerId, 5)
      setTimeout(() => scrollTo("#gatsby-focus-wrapper"), 0)
    }
  }, [])

  return (
    <ConfiguratorLayout activePageId="availability">
      <SEO title="Konfigurátor dostupnost" />
      <ConfiguratorAvailability />
    </ConfiguratorLayout>
  )
}

export default ConfiguratorAvailabilityPage
